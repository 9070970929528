import {
  CreateSessionDTO,
  ExamSessionTypesDTO,
  SessionDTO,
  SessionUserDTO,
} from 'recertify';
import Axios from '../utils/http.config';

const BASE_URL = `/session`;

export class SessionService {
  public static getSessionList(): Promise<SessionDTO[]> {
    return Axios.get<{data: SessionDTO[]}>(`${BASE_URL}/list`)
      .then(response => response.data.data);
  }

  public static markSessionAsCompleted(sessionId: number, sendEmail: boolean): Promise<any> {
    return Axios.post<{data: any}>(`admin/session/${sessionId}/markcompleted`, { sendEmail })

      .then(response => response.data);
  }

  public static create(session: CreateSessionDTO): Promise<SessionDTO> {
    return Axios.post<{data: SessionDTO}>(`${BASE_URL}/new`, session)
      .then(response => response.data.data);
  }

  public static getSessionInfo(id: number): Promise<SessionDTO & { examTypes: Array<{ id: number, name: string }>}> {
    return Axios.get<{data: SessionDTO & { examTypes: Array<{ id: number, name: string }>}}>(`${BASE_URL}/${id}/info`)
      .then(response => response.data.data);
  }

  public static getUsersInSession(id: number): Promise<SessionUserDTO[]> {
    return Axios.get<{data: SessionUserDTO[]}>(`${BASE_URL}/${id}/users`)
      .then(response => response.data.data);
  }

  public static getSessionEditLockedStatus(id: number): Promise<boolean> {
    return Axios.get<{data: boolean}>(`${BASE_URL}/${id}/isSessionEditLocked`)
      .then(response => response.data.data);
  }

  public static getSessionExamInfo(id: number) {
    return Axios.get<{data: {
      examTypes: Array<ExamSessionTypesDTO & {
        customRetakeLimit: number | null;
        subjects: number[];
      }>;
    };}>(`${BASE_URL}/${id}/exam-info`)
      .then(response => response.data.data);
  }

  public static async update(props: {
    session: {
      id: number;
      session_name: string;
      agency_id: number;
      end_date: string;
      start_date: string;
      users: number[];
      examData: Array<{
        examTypeId: number;
        subjectIds: number[];
      }>;
      timeLimit: number | null;
    };
  }) {
    await Axios.put(`${BASE_URL}/update`, { session: props.session });
  }

  public static async delete(sessionId: number) {
    await Axios.delete<{data: SessionDTO}>(`${BASE_URL}/${sessionId}/delete`);
  }

  public static async createBoosterSession(sessionId: number) {
    return Axios.post<{data: SessionDTO}>(`${BASE_URL}/${sessionId}/booster`)
      .then(response => response.data.data);
  }

  public static async publishSession(sessionId: number, sendEmail: boolean) {
    await Axios.put(`${BASE_URL}/${sessionId}/publish`, { sendEmail });
  }

  public static async emailSupervisors(sessionId: number) {
    await Axios.post(`${BASE_URL}/${sessionId}/email-supervisors`);
  }
}
